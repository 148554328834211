import { Body } from '@web/atomic';
import * as React from 'react';

interface INumberOfResultsProps {
  numberOfItems: number;
}

const NumberOfResults: React.FunctionComponent<INumberOfResultsProps> = (props) => {
  const { numberOfItems } = props;
  return <Body> {getSentence(numberOfItems)}</Body>;
};

const getSentence = (numberOfItems: number) => {
  switch (numberOfItems) {
    case 0:
      return 'Nenhum resultado encontrado.';
    default:
      return `Exibindo ${numberOfItems > 1 ? `${numberOfItems} resultados` : `${numberOfItems} resultado`}`;
  }
};

export default NumberOfResults;
