import { getListJsonLd } from '@lp-root/src/components/legacy/mol.seo/build-list-json-ld';
import { SEO } from '@lp-root/src/components/legacy/mol.seo/seo.component';
import { PaginationQueryParam } from '@lp-root/src/components/legacy/obj.pagination-container/pagination-query-param-getter';
import IndexLayout from '@lp-root/src/components/org.layout/layout.component';
import { appPaths } from '@lp-root/src/utils/path';
import { ListNoBullets } from '@lp-src/components/atm.list-no-bullets/ListNoBullets.component';
import NumberOfResults from '@lp-src/components/mol.number-of-results/number-of-results.component';
import { PageProps } from '@lp-src/utils/local-types';
import { Body, Caption, Col, FaIcon, Grid, H1, H2, H3, Hbox, Row, Separator } from '@web/atomic';
import { CellStyled, FieldsWrapper } from '@web/atomic/atm.wrapper/wrapper.component';
import { LazyLoadImage } from '@web/atomic/legacy/atm.lazy-load-image';
import { FuseSearch, IndexParams } from '@web/atomic/mol.local-search';
import { hasWindow } from '@web/utils/platform';
import { getCanonicalUrl, getDetailPageUrl } from '@web/utils/url';
import { graphql, Link, navigate } from 'gatsby';
import { isArray } from 'lodash';
import QueryString from 'querystringify';
import * as React from 'react';
import { ProfileSelectionSection } from '../home/components/profile-selection-section.component';

interface ArticleListItem {
  timeToRead: number;
  path: string;
  title: string;
  description: string;
  image: string;
  imageAlt: string;
}

interface SearchQueryParam extends PaginationQueryParam {
  busca: string;
  [key: string]: unknown;
}

const ArticleListTemplate: React.FunctionComponent<PageProps> = (props) => {
  const siteUrl = props.data.site.siteMetadata.siteUrl;
  const articles: ArticleListItem[] = props.data.allMarkdownRemark.nodes.map((item) => ({
    timeToRead: item.timeToRead,
    path: item.frontmatter.path,
    title: item.frontmatter.title,
    description: item.frontmatter.description,
    image: item.frontmatter.img,
    imageAlt: item.frontmatter.imgAlt,
  }));

  const indexParams = React.useRef<IndexParams<ArticleListItem>>({
    data: articles,
    keys: [
      {
        name: 'title',
        weight: 0.8,
      },
      { name: 'description', weight: 0.2 },
    ],
  });
  const [articlesList, setArticlesList] = React.useState(articles);
  const numberOfItems = articlesList.length;
  const onResult = React.useCallback((query: string, list: ArticleListItem[]) => {
    if (isArray(list)) {
      setArticlesList(list);
    } else {
      setArticlesList(articles);
    }

    const currentQueryParam: Partial<SearchQueryParam> = QueryString.parse(hasWindow() ? window.location.search : '');
    const nextQueryParam: Partial<SearchQueryParam> = { ...currentQueryParam, busca: query, page: 1 };
    const pathname = hasWindow() ? window.location.pathname : '';
    navigate(`${pathname}?${QueryString.stringify(nextQueryParam)}`);
  }, []);

  const currentQueryParam: Partial<SearchQueryParam> = QueryString.parse(hasWindow() ? window.location.search : '');
  const pageList: ArticleListItem[] = articlesList || [];

  return (
    <IndexLayout {...props}>
      <Grid>
        <Row mt mb>
          <Col xs={12} sm={10} md={8}>
            <H1>Artigos</H1>
          </Col>
        </Row>

        <Row mb>
          <Col xs={12} sm={10} md={8}>
            <FieldsWrapper>
              <FuseSearch
                initialValue={currentQueryParam.busca}
                indexParams={indexParams.current}
                onResultChange={onResult}
                placeholder={'Busque por um artigo'}
              />
            </FieldsWrapper>
            <Separator />
          </Col>
        </Row>
      </Grid>

      <SEO
        jsonld={getListJsonLd(articles.map((item) => ({ url: `${siteUrl}${item.path}` })))}
        socialMedia={{
          canonicalUrl: getCanonicalUrl(siteUrl, appPaths.blog.path),
          title: 'Artigos',
          description: 'Veja nossos artigos sobre insônia e o programa da Vigilantes do Sono',
        }}
      />
      <Grid>
        <Row mb center="xs">
          <Col xs={12}>
            {articlesList.length > 0 ? (
              <>
                <Row mt mb>
                  <Col>
                    <NumberOfResults numberOfItems={numberOfItems} />
                    <Separator />
                  </Col>
                </Row>

                <Row center="xs" mb>
                  <Col xs={12}>
                    <ListNoBullets>
                      {pageList &&
                        pageList.map((item) => (
                          <CellStyled key={item.title} as="li">
                            <Link to={getDetailPageUrl(appPaths.blog.path, item.path)}>
                              <Row center="xs" mb>
                                <Col xs={12} md={3}>
                                  <LazyLoadImage alt={item.imageAlt} src={item.image} />
                                </Col>
                                <Col xs={12} md={9}>
                                  <Hbox>
                                    <Hbox.Item>
                                      <H2 cell>{item.title}</H2>
                                      <Body>{item.description}</Body>
                                      <Caption>{item.timeToRead} min de leitura</Caption>
                                    </Hbox.Item>
                                    <Hbox.Separator />
                                    <Hbox.Item vAlign="center" wrap>
                                      <FaIcon.ChevronRight />
                                    </Hbox.Item>
                                  </Hbox>
                                </Col>
                              </Row>
                            </Link>
                          </CellStyled>
                        ))}
                    </ListNoBullets>
                  </Col>
                </Row>
              </>
            ) : (
              <H3> Nenhum resultado encontrado </H3>
            )}
          </Col>
        </Row>
      </Grid>

      <ProfileSelectionSection />
    </IndexLayout>
  );
};

export default ArticleListTemplate;

export const query = graphql`
  query ArticleList {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/_cms/article/" }, frontmatter: { showOnArticleList: { eq: true } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        timeToRead
        frontmatter {
          path
          title
          description
          img
          imgAlt
          date
        }
      }
    }
  }
`;
function useCallback(arg0: (list: any) => void, arg1: undefined[]) {
  throw new Error('Function not implemented.');
}
